import React, { useState, useContext } from 'react';
import { DataContext } from '../contexts/DataContext';
import { supabase } from '../supabaseClient';


function Settings() {
    const { beerBrands, distributors } = useContext(DataContext);

    // State to manage edit mode and track changes
    const [editMode, setEditMode] = useState(false);
    const [updatedBeerBrands, setUpdatedBeerBrands] = useState([...beerBrands]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Function to handle edit mode toggle
    const toggleEditMode = () => {
        setEditMode(!editMode);
        if (!editMode) {
            // Exit edit mode, reset changes
            setUpdatedBeerBrands([...beerBrands]);
        }
    };

    // Function to handle datetime changes for each brand
    const handleDatetimeChange = (index, field, value) => {
        const updatedBrands = [...updatedBeerBrands];
        updatedBrands[index][field] = value;
        setUpdatedBeerBrands(updatedBrands);
    };

    // Submit changes to Supabase
    const handleSubmitChanges = async () => {
        setLoading(true);
        setError(null);
        try {
            const updates = updatedBeerBrands.map((brand) => ({
                beerbrandid: brand.beerbrandid,
                crashdatetime: brand.crashdatetime,
                dryhopdatetime: brand.dryhopdatetime,
                estimatedpackagingdatetime: brand.estimatedpackagingdatetime
            }));
            for (const update of updates) {
                const { error } = await supabase
                    .from('beerbrands')
                    .update({
                        crashdatetime: update.crashdatetime,
                        dryhopdatetime: update.dryhopdatetime,
                        estimatedpackagingdatetime: update.estimatedpackagingdatetime
                    })
                    .eq('beerbrandid', update.beerbrandid);

                if (error) throw error;
            }

            setEditMode(false);
        } catch (error) {
            setError('Failed to update records. Please try again.');
            console.error('Error updating beer brands:', error);
        } finally {
            setLoading(false);
        }
    };

    // Function to add a new beer brand
    const handleAddNewBrand = async () => {
        const brandName = prompt("Enter the new brand name:");
        if (!brandName) return;

        try {
            // Fetch the current max beerbrandid
            const { data: maxData, error: maxError } = await supabase
                .from('beerbrands')
                .select('beerbrandid')
                .order('beerbrandid', { ascending: false })
                .limit(1);

            if (maxError) throw maxError;

            // Calculate next beerbrandid
            const nextId = maxData.length > 0 ? maxData[0].beerbrandid + 1 : 1;

            // Insert the new brand with the calculated beerbrandid
            const { data, error } = await supabase
                .from('beerbrands')
                .insert([{
                    beerbrandid: nextId, // Use calculated beerbrandid
                    brandname: brandName
                }])
                .select('*');

            if (error) throw error;

            // Update the local state with the new brand
            setUpdatedBeerBrands([...updatedBeerBrands, ...data]);
        } catch (error) {
            setError('Failed to add new brand. Please try again.');
            console.error('Error adding new beer brand:', error);
        }
    };


    // Function to cancel changes and exit edit mode
    const handleCancelChanges = () => {
        setUpdatedBeerBrands([...beerBrands]);
        setEditMode(false);
    };

    return (
        <div className="settings-page">
            <div className="tables-container">
                {/* Beer Brands Table */}
                <div className="table-container">
                    <div className='settings-brand-header'>
                        <h2>Beer Brands</h2>
                        <div className="edit-mode-header">
                            {!editMode ? (
                                <button className="edit-button" onClick={toggleEditMode}>Edit</button>
                            ) : (
                                <>
                                    <button className="brand-date-submit-button" onClick={handleSubmitChanges} disabled={loading}>
                                        {loading ? 'Saving...' : 'Submit'}
                                    </button>
                                    <button className="brand-date-cancel-button" onClick={handleCancelChanges}>Cancel</button>
                                </>
                            )}
                        </div>
                    </div>
                    {error && <p className="error-message">{error}</p>}
                    <table className="data-table">
                        <thead>
                            <tr>
                                <th>Brand Name</th>
                                <th>Brew to Crash</th>
                                <th>Brew to Dry hop</th>
                                <th>Crash to Packaging</th>
                            </tr>
                        </thead>
                        <tbody>
                            {updatedBeerBrands.map((brand, index) => (
                                <tr key={brand.beerbrandid}>
                                    <td>{brand.brandname}</td>
                                    <td>
                                        {editMode ? (
                                            <input
                                                className='shorter-input'
                                                type="number"
                                                value={brand.crashdatetime}
                                                onChange={(e) =>
                                                    handleDatetimeChange(index, 'crashdatetime', e.target.value)
                                                }
                                            />
                                        ) : (
                                            brand.crashdatetime
                                        )}
                                    </td>
                                    <td>
                                        {editMode ? (
                                            <input
                                                className='shorter-input'
                                                type="number"
                                                value={brand.dryhopdatetime}
                                                onChange={(e) =>
                                                    handleDatetimeChange(index, 'dryhopdatetime', e.target.value)
                                                }
                                            />
                                        ) : (
                                            brand.dryhopdatetime
                                        )}
                                    </td>
                                    <td>
                                        {editMode ? (
                                            <input
                                                className='shorter-input'
                                                type="number"
                                                value={brand.estimatedpackagingdatetime}
                                                onChange={(e) =>
                                                    handleDatetimeChange(index, 'estimatedpackagingdatetime', e.target.value)
                                                }
                                            />
                                        ) : (
                                            brand.estimatedpackagingdatetime
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <button className="add-button" onClick={handleAddNewBrand}>Add New Brand</button>
                </div>

                {/* Distributors Table */}
                <div className="table-container">
                    <h2>Distributors</h2>
                    <table className="data-table">
                        <thead>
                            <tr>
                                <th>Distributor ID</th>
                                <th>Distributor Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {distributors.map((distributor) => (
                                <tr key={distributor.distributorid}>
                                    <td>{distributor.distributorid}</td>
                                    <td>{distributor.distributorname}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <button className="add-button" onClick={() => console.log('Add new distributor clicked')}>Add New Distributor(NOT WORKING YET)</button>
                </div>
            </div>
        </div>
    );
}

export default Settings;
