import React, { useState, useEffect } from 'react';

const OrderForm = () => {
    // Set initial state for sections and month
    const [sections, setSections] = useState([
        {
            caseType: 'Cases',
            brands: [
                { brand: '51K IPA', westBranch: 100, saginaw: 0, stIgnace: 0, fulfilled: 0, outstanding: null },
                { brand: 'Grand Rabbits Cream Ale', westBranch: 50, saginaw: 0, stIgnace: 20, fulfilled: 0, outstanding: null },
                { brand: 'Mykiss IPA', westBranch: 100, saginaw: 0, stIgnace: 0, fulfilled: 0, outstanding: null },
            ],
        },
        {
            caseType: '1/2 BBL',
            brands: [
                { brand: '51K IPA', westBranch: 120, saginaw: 0, stIgnace: 0, fulfilled: 0, outstanding: null },
                { brand: 'Grand Rabbits Cream Ale', westBranch: 60, saginaw: 0, stIgnace: 24, fulfilled: 0, outstanding: null },
                { brand: 'Mykiss IPA', westBranch: 120, saginaw: 0, stIgnace: 0, fulfilled: 0, outstanding: null },
            ],
        },
        {
            caseType: '1/4 BBL',
            brands: [
                { brand: '51K IPA', westBranch: 80, saginaw: 0, stIgnace: 0, fulfilled: 0, outstanding: null },
                { brand: 'Grand Rabbits Cream Ale', westBranch: 40, saginaw: 24, stIgnace: 0, fulfilled: 0, outstanding: null },
                { brand: 'Mykiss IPA', westBranch: 80, saginaw: 0, stIgnace: 0, fulfilled: 0, outstanding: null },
            ],
        },
        {
            caseType: '1/6 BBL',
            brands: [
                { brand: '51K IPA', westBranch: 60, saginaw: 0, stIgnace: 0, fulfilled: 0, outstanding: null },
                { brand: 'Grand Rabbits Cream Ale', westBranch: 30, saginaw: 0, stIgnace: 0, fulfilled: 0, outstanding: null },
                { brand: 'Mykiss IPA', westBranch: 60, saginaw: 0, stIgnace: 0, fulfilled: 0, outstanding: null },
            ],
        },
    ]);

    const [currentMonth, setCurrentMonth] = useState('January 2025'); // Placeholder month and year

    // Function to calculate Outstanding (only when values change)
    const calculateOutstanding = (brand) => {
        return (brand.westBranch + brand.saginaw + brand.stIgnace) - brand.fulfilled;
    };

    const handleChange = (sectionIndex, brandIndex, field, value) => {
        const updatedSections = [...sections];
        updatedSections[sectionIndex].brands[brandIndex][field] = Number(value);

        // Recalculate Outstanding when any value changes
        const brand = updatedSections[sectionIndex].brands[brandIndex];
        brand.outstanding = calculateOutstanding(brand);

        setSections(updatedSections);
    };

    // Initial calculation of Outstanding when component mounts
    useEffect(() => {
        const updatedSections = sections.map((section) => ({
            ...section,
            brands: section.brands.map((brand) => ({
                ...brand,
                outstanding: calculateOutstanding(brand),
            })),
        }));
        setSections(updatedSections);
    }, []);

    const handleBackMonth = () => {
        console.log('Back button clicked');
    };

    const handleForwardMonth = () => {
        console.log('Forward button clicked');
    };

    return (
        <div className="order-form">
            {/* Order Header Section */}
            <div className="order-header">
                <h2>Order Form (dummy page) </h2>
                <div className="month-navigation">
                    <button onClick={handleBackMonth}>Back</button>
                    <span>{currentMonth}</span>
                    <button onClick={handleForwardMonth}>Forward</button>
                </div>
            </div>

            {/* Table Section */}
            <table>
                <thead>
                    <tr>
                        <th>Brand</th>
                        <th>West Branch</th>
                        <th>Saginaw</th>
                        <th>ST. Ignace</th>
                        <th>Fulfilled</th>
                        <th>Outstanding</th>
                    </tr>
                </thead>
                <tbody>
                    {sections.map((section, sectionIndex) => (
                        <React.Fragment key={sectionIndex}>
                            <tr>
                                <td colSpan={6} style={{ textAlign: 'center', backgroundColor: '#f46524', fontWeight: 'bold' }}>
                                    {section.caseType}
                                </td>
                            </tr>
                            {section.brands.map((brand, brandIndex) => (
                                <tr key={brandIndex}>
                                    <td>{brand.brand}</td>
                                    <td>
                                        <input
                                            type="number"
                                            value={brand.westBranch}
                                            onChange={(e) => handleChange(sectionIndex, brandIndex, 'westBranch', e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            value={brand.saginaw}
                                            onChange={(e) => handleChange(sectionIndex, brandIndex, 'saginaw', e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            value={brand.stIgnace}
                                            onChange={(e) => handleChange(sectionIndex, brandIndex, 'stIgnace', e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            value={brand.fulfilled}
                                            onChange={(e) => handleChange(sectionIndex, brandIndex, 'fulfilled', e.target.value)}
                                        />
                                    </td>
                                    <td>{brand.outstanding !== null ? brand.outstanding : 0}</td> {/* Outstanding is live calculated */}
                                </tr>
                            ))}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default OrderForm;
