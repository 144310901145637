import React, { useEffect, useCallback, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { supabase } from '../supabaseClient';
import { fetchBeerBrands, fetchVessels, fetchBrites } from '../services/brewServices';
import { useBrewState } from '../hooks/useBrewState';
import { styles } from '../styles/brewStyles';
import BrewSelection from './brewComponents/BrewSelection';
import BrewForm from './brewComponents/BrewForm';
import BrewBriteStatus from './brewComponents/BrewBriteStatus';
import BrewVesselStatus from './brewComponents/BrewVesselStatus';
import BrewCalendar from './brewComponents/BrewCalendar';
import BrewBatchTable from './brewComponents/BrewBatchTable';

const Brew = () => {
    const {
        beerBrands, setBeerBrands,
        vessels, setVessels,
        brites, setBrites,
        activeBatches, setActiveBatches,
        allBatches, setAllBatches,
        vesselStyles, setVesselStyles,
        briteStyles, setBriteStyles,
        selectedVessel, setSelectedVessel,
        selectedBrite, setSelectedBrite,
        showNewBatchForm, setShowNewBatchForm,
        showFullBrite, setShowFullBrite,
        selectingBrite, setSelectingBrite,
        brewState, setBrewState
    } = useBrewState();

    // New state for view control
    const [view, setView] = useState('tanks'); // Default to 'tanks'
    const [selectedBrites, setSelectedBrites] = useState([]);
    const [remainingVolume, setRemainingVolume] = useState(0);
    const [error, setError] = useState(null); // New error state to track any errors

    const fetchAllBatches = useCallback(async () => {
        try {
            const { data, error } = await supabase.from('batch').select('*');
            if (error) throw error;
            setAllBatches(data);
        } catch (error) {
            console.error('Error fetching all batches:', error);
        }
    }, [setAllBatches]);

    const fetchActiveBatches = useCallback(async () => {
        const fvBatchIds = vessels.map(vessel => vessel.batchid).filter(Boolean);
        const btBatchIds = brites.map(brite => brite.batchid).filter(Boolean);
        const combinedBatchIds = [...new Set([...fvBatchIds, ...btBatchIds])];

        if (combinedBatchIds.length === 0) {
            console.log("No active batches found in vessels or brites");
            return;
        }

        try {
            const { data, error } = await supabase.from('batch').select('*').in('batchid', combinedBatchIds);
            if (error) throw error;
            setActiveBatches(data);
        } catch (error) {
            console.error('Error fetching active batches:', error);
        }
    }, [vessels, brites, setActiveBatches]);

    const refreshData = useCallback(async () => {
        try {
            const brands = await fetchBeerBrands(supabase);
            setBeerBrands(brands);

            const vesselsData = await fetchVessels(supabase);
            setVessels(vesselsData.vessels);
            setVesselStyles(vesselsData.styles);

            const britesData = await fetchBrites(supabase);
            setBrites(britesData.brites);
            setBriteStyles(britesData.styles);

            await fetchAllBatches();
            await fetchActiveBatches();
            setError(null);
        } catch (error) {
            console.error('Error refreshing data:', error);
        }
    }, [fetchAllBatches, fetchActiveBatches, setBeerBrands, setVessels, setVesselStyles, setBrites, setBriteStyles]);

    const updateFVtoBT = useCallback(async () => {
        const selectedFV = vessels.find(v => v.fermentationvesselid === selectedVessel);
        const batchId = selectedFV ? selectedFV.batchid : null;

        if (!batchId) {
            console.error('No batch associated with the selected FV');
            return;
        }

        try {
            const updates = selectedBrites.map(async (briteId) => {
                const brite = brites.find(b => b.britetankids === briteId);
                if (brite) {
                    const existingBatchIds = Array.isArray(brite.batchid) ? brite.batchid : (brite.batchid ? [brite.batchid] : []);
                    const newBatchIds = [...existingBatchIds, batchId]; // Ensure batch IDs are accumulated in an array
                    const currentVolume = brite.status === "Full" ? brite.capacity :
                        brite.status ? parseFloat(brite.status.split('/')[0]) :
                            0; // Handle null status by initializing to 0
                    const newVolume = currentVolume + selectedFV.capacity;
                    const newStatus = newVolume >= brite.capacity ? "Full" : `${newVolume}/${brite.capacity}`;

                    return supabase.from('britetanks').update({
                        status: newStatus,
                        batchid: newBatchIds // Ensure this is always an array
                    }).eq('britetankids', briteId);
                }
            });

            // Update the fermentation vessel as empty
            updates.push(supabase.from('fermentationvessels').update({
                status: null,
                batchid: null
            }).eq('fermentationvesselid', selectedVessel));

            // Update the batch record with new brite tank ids
            updates.push(supabase.from('batch').update({
                britetankids: selectedBrites
            }).eq('batchid', batchId));

            const results = await Promise.all(updates);

            if (results.some(result => result.error)) {
                console.error('Error updating records:', results.filter(result => result.error).map(result => result.error));
                return;
            }

            setSelectingBrite(false);
            setRemainingVolume(0);
            await refreshData();
        } catch (error) {
            console.error('Error updating records:', error);
        }
    }, [vessels, brites, selectedVessel, selectedBrites, setSelectingBrite, refreshData]);

    const initializeData = useCallback(async () => {
        try {
            const brands = await fetchBeerBrands(supabase);
            setBeerBrands(brands);

            const vesselsData = await fetchVessels(supabase);
            setVessels(vesselsData.vessels);
            setVesselStyles(vesselsData.styles);

            const britesData = await fetchBrites(supabase);
            setBrites(britesData.brites);
            setBriteStyles(britesData.styles);
        } catch (error) {
            console.error('Error initializing brew data:', error);
        }
    }, [setBeerBrands, setVessels, setVesselStyles, setBrites, setBriteStyles]);

    const handleBriteSelection = useCallback(() => {
        const isBriteFull = activeBatches.some(b => b.britetankids && b.britetankids.includes(selectedBrite));

        if (selectingBrite) {
            setSelectingBrite(false);
        } else {
            setShowFullBrite(isBriteFull);
            setSelectingBrite(false);
            setShowNewBatchForm(false);
        }
    }, [activeBatches, selectedBrite, selectingBrite, setSelectingBrite, setShowFullBrite, setShowNewBatchForm]);

    useEffect(() => {
        initializeData();
        fetchAllBatches();
    }, [initializeData, fetchAllBatches]);

    useEffect(() => {
        if (vessels.length > 0 && brites.length > 0) {
            fetchActiveBatches();
        }
    }, [vessels, brites, fetchActiveBatches]);

    useEffect(() => {
        if (selectedBrite !== null) {
            handleBriteSelection();
        }
    }, [selectedBrite, handleBriteSelection]);

    const selectVessel = (vesselId) => {
        setError(null);
        setSelectingBrite(false);
        setSelectedVessel(vesselId);
        setShowNewBatchForm(true);
        setShowFullBrite(false);
        setSelectedBrite(null);

        setBrewState({
            batchid: null,
            beerbrandid: null,
            brewdate: null,
            brewdateworkerid: null,
            originalgravity: null,
            fermentationvesselid: null,
            finalgravity: null,
            yield: null,
            estimatedpackagingdate: null,
            status: null,
            dryhopdate: null,
            dryhopdateworkerid: null,
            crashdate: null,
            crashdateworkerid: null,
            britetankids: null,
            notes: null
        });
    };

    const selectBrite = (briteId) => {
        setError(null);
        if (selectingBrite) {
            const selectedBrite = brites.find(b => b.britetankids === briteId);
            if (selectedBrite) {
                if (remainingVolume >= selectedBrite.capacity) {
                    setSelectedBrites(prev => [...prev, briteId]);
                    setRemainingVolume(prev => prev - selectedBrite.capacity);
                } else if (remainingVolume < selectedBrite.capacity && remainingVolume != 0) {
                    setSelectedBrites(prev => [...prev, briteId]);
                    setRemainingVolume(prev => prev - remainingVolume);
                }
                else {
                    alert("Not enough remaining volume for this brite tank.");
                }
            }
        }
        else {
            setSelectedBrite(briteId);
        }
    };

    const unselectBrite = (briteId) => {
        const briteToRemove = brites.find(b => b.britetankids === briteId);
        if (briteToRemove) {
            setSelectedBrites(prev => prev.filter(id => id !== briteId));
            setRemainingVolume(prev => prev + briteToRemove.capacity);
        }
    };

    const handleNewBatchSubmit = async () => {
        try {
            setError(null); // Reset error state before submission
            const { error: createError } = await supabase.from('batch').insert([
                {
                    ...brewState,
                    fermentationvesselid: selectedVessel,
                    moveToBriteDate: brewState.moveToBriteDate,
                    brewdate: brewState.brewdate,
                    dryhopdate: brewState.dryhopdate,
                    crashdate: brewState.crashdate,
                    estimatedpackagingdate: brewState.estimatedpackagingdate
                }
            ]);

            if (createError) {
                setError('Error creating new batch: ' + createError.message);
                return;
            }

            const { error: updateError } = await supabase.from('fermentationvessels')
                .update({ status: 'Full', batchid: brewState.batchid })
                .eq('fermentationvesselid', selectedVessel);

            if (updateError) {
                setError('Error updating fermentation vessel: ' + updateError.message);
                return;
            }

            await refreshData();
            setShowNewBatchForm(false);
            setShowFullBrite(false);
        } catch (error) {
            setError('An unexpected error occurred: ' + error.message);
        }
    };

    const handleMovetoBriteSubmit = async (event) => {
        event.preventDefault();
        const selectedFV = vessels.find(v => v.fermentationvesselid === selectedVessel);

        setRemainingVolume(selectedFV.capacity);
        setSelectedBrites([]);

        setSelectingBrite(true);
        setShowNewBatchForm(false);
    };

    const handlePreformRunSubmit = async (event) => {
        event.preventDefault();

        if (selectedBrite) {
            try {
                const { data: briteData, error: briteFetchError } = await supabase
                    .from('britetanks')
                    .select('batchid')
                    .eq('britetankids', selectedBrite);

                if (briteFetchError) {
                    console.error('Error fetching batchid from britetanks:', briteFetchError);
                    return; // Exit if there's an error fetching batchid
                }

                const batchIds = briteData[0]?.batchid || [];

                if (batchIds.length === 0) {
                    console.log('No batchid found for the selected brite tank');
                    return;
                }

                const { error: batchError } = await supabase
                    .from('batch')
                    .update({ status: 'Ready To Can' })
                    .in('batchid', batchIds); // Use "in" to update all matching batchids

                if (batchError) {
                    console.error('Error updating batch status:', batchError);
                    return; // Exit if there's an error updating the batch
                }

                console.log('Batch status updated successfully for batchids:', batchIds);

                const { error: briteError } = await supabase
                    .from('britetanks')
                    .update({ batchid: null, status: null, yield: null })
                    .eq('britetankids', selectedBrite);

                if (briteError) {
                    console.error('Error updating brite tank:', briteError);
                } else {
                    console.log('Brite tank updated successfully');
                    await refreshData();
                    console.log(brites);
                    console.log(activeBatches);
                    setShowNewBatchForm(false);
                    setShowFullBrite(false);
                    setSelectedVessel(null);
                    setSelectedBrite(null);
                    await fetchActiveBatches();
                    console.log(brites);
                    console.log(activeBatches);
                }

            } catch (err) {
                console.error('Error during brite tank or batch update:', err);
            }
        } else {
            console.log('No brite tank selected for update');
        }
    };

    const handleDeleteBatch = async (batchIds) => {
        if (window.confirm('Are you sure you want to delete this batch? This action cannot be undone.')) {
            const batchIdsArray = Array.isArray(batchIds) ? batchIds : [batchIds];
            for (const batchId of batchIdsArray) {
                try {
                    const batch = allBatches.find(b => b.batchid === batchId);
                    if (!batch) {
                        console.error('Batch not found:', batchId);
                        return;
                    }

                    const vessel = vessels.find(v => v.batchid === batchId);
                    if (vessel) {
                        const { error: fvError } = await supabase
                            .from('fermentationvessels')
                            .update({ batchid: null, status: null })
                            .eq('fermentationvesselid', vessel.fermentationvesselid);
                        if (fvError) throw fvError;
                    }

                    const briteMatches = brites.filter(b => b.batchid && b.batchid.includes(batchId));
                    console.log(briteMatches);

                    if (briteMatches.length > 0) {
                        for (const brite of briteMatches) {
                            const { error: btUpdateError } = await supabase
                                .from('britetanks')
                                .update({
                                    batchid: null,
                                    status: null,
                                    yield: null
                                })
                                .eq('britetankids', brite.britetankids);

                            if (btUpdateError) throw btUpdateError;
                        }
                    }
                    const { error: batchError } = await supabase
                        .from('batch')
                        .delete()
                        .eq('batchid', batchId);
                    if (batchError) throw batchError;

                    await refreshData();
                    setShowNewBatchForm(false);
                    setShowFullBrite(false);
                    setSelectedVessel(null);
                    setSelectedBrite(null);
                } catch (error) {
                    console.error('Error deleting batch:', error);
                }
            }
        }
    };

    const handleCancel = () => {
        setShowNewBatchForm(false);
        setSelectedVessel(null);
    };

    const updateBatchInDB = async (batchData) => {
        try {
            const { error } = await supabase
                .from('batch')
                .update(batchData)
                .eq('batchid', batchData.batchid);

            if (error) {
                console.error('Error updating batch data:', error);
            } else {
                await fetchActiveBatches();
                await fetchAllBatches();
            }
        } catch (error) {
            console.error('Error updating batch data:', error);
        }
    };

    const updateBriteInDB = async (briteData) => {
        try {
            const { error } = await supabase
                .from('britetanks')
                .update(briteData)
                .eq('britetankids', briteData.britetankids);

            if (error) {
                console.error('Error updating brite data:', error);
            } else {
                await refreshData();
                try {
                    const { error } = await supabase
                        .from('batch')
                        .update({ yield: briteData.yield })
                        .eq('batchid', briteData.batchid);

                    if (error) {
                        console.error('Error updating batch data:', error);
                    } else {
                        await fetchActiveBatches();
                        await fetchAllBatches();
                    }
                } catch (error) {
                    console.error('Error updating batch data:', error);
                }
            }
        } catch (error) {
            console.error('Error updating brite data:', error);
        }
    };

    const moveToBriteButtonText = () => {
        const briteText = vessels.find(v => v.fermentationvesselid === selectedVessel).capacity === 120
            ? "Select a Brite tank to move to (Select Brites to match 120-barrel capacity)"
            : "Select a Brite tank to move to";

        return briteText;
    }

    return (
        <div>
            <div className='view-switch'>
                <button
                    className='brewSwitch'
                    onClick={() => setView('tanks')}
                    disabled={view === 'tanks'} // Disable the button if the current view is 'tanks'
                >
                    Tanks View
                </button>
                <button
                    className='brewSwitch'
                    onClick={() => setView('calendar')}
                    disabled={view === 'calendar'} // Disable the button if the current view is 'calendar'
                >
                    Calendar View
                </button>
                <button
                    className='brewSwitch'
                    onClick={() => setView('batch')}
                    disabled={view === 'batch'} // Disable the button if the current view is 'batch'
                >
                    Batch List
                </button>
            </div>
            {view === 'tanks' && (
                <div className='split-container'>
                    <BrewSelection
                        vesselStyles={vesselStyles}
                        briteStyles={briteStyles}
                        onSelectVessel={selectVessel}
                        onSelectBrite={selectBrite}
                        activeBatches={activeBatches}
                        beerBrands={beerBrands}
                    />
                    <div className='right-pane'>
                        <div className='info-box'>
                            {error && <p style={{ color: 'red' }}>{error}</p>}

                            {showNewBatchForm && selectedVessel && vesselStyles[selectedVessel] === styles.openStyle && (
                                <BrewForm
                                    brewState={brewState}
                                    setBrewState={setBrewState}
                                    selectedVessel={selectedVessel}
                                    vessels={vessels}
                                    beerBrands={beerBrands}
                                    handleNewBatchSubmit={handleNewBatchSubmit}
                                    handleCancel={handleCancel}
                                />
                            )}
                            {showNewBatchForm && selectedVessel && vesselStyles[selectedVessel] !== styles.openStyle && (
                                <BrewVesselStatus
                                    selectedVessel={selectedVessel}
                                    vessels={vessels}
                                    activeBatches={activeBatches}
                                    beerBrands={beerBrands}
                                    setBrewState={setBrewState}
                                    handleMovetoBriteSubmit={handleMovetoBriteSubmit}
                                    handleDeleteBatch={handleDeleteBatch}
                                    updateBatchInDB={updateBatchInDB}
                                />
                            )}
                            {selectingBrite && (
                                <div>
                                    <h3>{moveToBriteButtonText()}</h3>
                                    <p>Remaining Volume: {remainingVolume} barrels</p>
                                    <div>
                                        <h4>Available Brites:</h4>
                                        <ul>
                                            {brites
                                                .sort((a, b) => a.britetankids - b.britetankids) // Sort brites by their britetankids
                                                .map(brite => (
                                                    <li key={brite.britetankids}>
                                                        <button
                                                            onClick={() => selectBrite(brite.britetankids)}
                                                            disabled={brite.status === 'Full' || remainingVolume === 0 || selectedBrites.includes(brite.britetankids)}
                                                        >
                                                            Brite {brite.britetankids} ({brite.capacity} barrels)
                                                        </button>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                    {selectedBrites.length > 0 && (
                                        <div>
                                            <h4>Selected Brites:</h4>
                                            <ul>
                                                {selectedBrites.map(briteId => {
                                                    const brite = brites.find(b => b.britetankids === briteId);
                                                    return (
                                                        <li key={briteId}>
                                                            Brite {briteId} ({brite.capacity} barrels)
                                                            <button onClick={() => unselectBrite(briteId)}>Unselect</button>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    )}
                                    {remainingVolume === 0 && (
                                        <button onClick={updateFVtoBT}>Confirm Move to Brite(s)</button>
                                    )}
                                </div>
                            )}
                            {showFullBrite && (
                                <BrewBriteStatus
                                    brites={brites}
                                    selectedBrite={selectedBrite}
                                    activeBatches={activeBatches}
                                    beerBrands={beerBrands}
                                    handlePreformRunSubmit={handlePreformRunSubmit}
                                    handleDeleteBatch={handleDeleteBatch}
                                    updateBatchInDB={updateBatchInDB}
                                    updateBriteInDB={updateBriteInDB}
                                />
                            )}
                            {!showFullBrite && !showNewBatchForm && !selectingBrite && <p>Select a fermenter to see details or create a new batch.</p>}
                        </div>
                    </div>

                </div>
            )}

            {view === 'calendar' && (
                <div className='info-row'>
                    <BrewCalendar
                        batches={allBatches}
                        vessels={vessels}
                        brites={brites}
                        onDeleteBatch={handleDeleteBatch}
                    />
                </div>
            )}

            {view === 'batch' && (
                <BrewBatchTable></BrewBatchTable>
            )}
        </div>
    );
};

export default Brew;
