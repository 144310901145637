// RequestBoard.js
import React, { useContext, useState } from 'react';
import { supabase } from '../supabaseClient';
import { DataContext } from '../contexts/DataContext';

function RequestBoard() {
    const { requests, refreshData } = useContext(DataContext);
    const [activeTab, setActiveTab] = useState('Pending');

    // Function to update request category by changing title
    const updateRequestCategory = async (requestId, newTitle) => {
        const { error } = await supabase
            .from('requests')
            .update({ title: newTitle })
            .match({ id: requestId });

        if (error) console.error('Error updating request', error);
        else refreshData();
    };

    // Function to delete request
    const deleteRequest = async (requestId) => {
        if (window.confirm("Are you sure you want to delete this request?")) {
            const { error } = await supabase.from('requests').delete().match({ id: requestId });
            if (error) console.error('Error deleting request', error);
            else refreshData();
        }
    };

    // Normalize and sort requests into categories based on title
    const sortedRequests = requests.sort((a, b) => new Date(b.date) - new Date(a.date));

    // Ensure each request has a recognized category
    const categorizedRequests = sortedRequests.map(req => ({
        ...req,
        category: ["Pending", "In Progress", "Archived"].includes(req.title) ? req.title : "Pending"
    }));

    // Filter requests based on active tab
    const filteredRequests = categorizedRequests.filter(req => req.category === activeTab);

    return (
        <div className="request-board">
            <h2 className="request-title">Requests</h2>

            {/* Tab Navigation */}
            <div className="tabs">
                {['Pending', 'In Progress', 'Archived'].map(tab => (
                    <button
                        key={tab}
                        className={`tab-button ${activeTab === tab ? 'active' : ''}`}
                        onClick={() => setActiveTab(tab)}
                    >
                        {tab}
                    </button>
                ))}
            </div>

            {/* Request List */}
            {filteredRequests.length > 0 ? (
                <div className="request-list">
                    {filteredRequests.map((request) => (
                        <div className="request-card" key={request.id}>
                            <button className="delete-btn" onClick={() => deleteRequest(request.id)}>✖</button>
                            <div className="request-content">
                                <p className="request-date">{new Date(request.date).toLocaleDateString()}</p>
                                <p className="request-title-text">{request.title}</p>
                                <p className="request-description">{request.description}</p>
                            </div>

                            {/* Status Update Buttons */}
                            <div className="request-actions">
                                {activeTab === 'Pending' && (
                                    <button className="progress-btn" onClick={() => updateRequestCategory(request.id, 'In Progress')}>
                                        Start Progress
                                    </button>
                                )}
                                {activeTab === 'In Progress' && (
                                    <button className="complete-btn" onClick={() => updateRequestCategory(request.id, 'Archived')}>
                                        Mark as Done
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <p className="no-requests">No requests in this category</p>
            )}
        </div>
    );
}

export default RequestBoard;
