import React, { useEffect, useState, useCallback } from 'react';
import { supabase } from '../../supabaseClient';

const BrewBatchTable = () => {
    const [batches, setBatches] = useState([]);
    const [error, setError] = useState(null);

    const fetchBatches = useCallback(async () => {
        try {
            const { data, error } = await supabase
                .from('batch')
                .select(`
                    batchid,
                    beerbrandid,
                    brewdate,
                    originalgravity,
                    finalgravity,
                    fermentationvesselid,
                    britetankids,
                    yield,
                    status,
                    beerbrands(brandname) -- Use correct column for beer brand name
                `);

            if (error) throw error;

            const sortedData = data.sort((a, b) => new Date(b.brewdate) - new Date(a.brewdate));
            setBatches(sortedData);
        } catch (err) {
            console.error('Error fetching batches:', err);
            setError('Failed to fetch batch data. Please try again later.');
        }
    }, []);

    useEffect(() => {
        fetchBatches();
    }, [fetchBatches]);

    return (
        <div>
            <h2>Batch List</h2>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <table border="1" cellPadding="8" cellSpacing="0">
                <thead>
                    <tr>
                        <th>Batch ID</th>
                        <th>Beer Brand</th>
                        <th>Brew Date</th>
                        <th>Original Gravity</th>
                        <th>Final Gravity</th>
                        <th>Fermentation Vessel</th>
                        <th>Brite Tanks</th>
                        <th>Yield</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {batches.map(batch => (
                        <tr key={batch.batchid}>
                            <td>{batch.batchid}</td>
                            <td>{batch.beerbrands?.brandname || 'Unknown'}</td>
                            <td>{batch.brewdate}</td>
                            <td>{batch.originalgravity}</td>
                            <td>{batch.finalgravity}</td>
                            <td>{batch.fermentationvesselid || 'N/A'}</td>
                            <td>
                                {Array.isArray(batch.britetankids)
                                    ? batch.britetankids.join(', ')
                                    : 'N/A'}
                            </td>
                            <td>{batch.yield || 'N/A'}</td>
                            <td>{batch.status}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default BrewBatchTable;
