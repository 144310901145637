import React, { useContext, useEffect, useState } from 'react';
import './App.css';
import './Water.css';
import { DataContext } from './contexts/DataContext.js';
import NavBar from './components/NavBar.js';
import InputRun from './components/InputRun.js';
import RunLogs from './components/RunLogs.js';
import InputAllocation from './components/InputAllocation.js';
import AllocationLogs from './components/AllocationLogs.js';
import Inventory from './components/Inventory.js';
import Brew from './components/Brew.js';
import Login from './components/Login';
import DistroAllo from './components/DistroAllo.js';
import InputRequest from './components/InputRequest.js';
import RequestBoard from './components/RequestBoard.js';
import ToPubAllocation from './components/ToPubAllocation.js';
import EventsAllocation from './components/EventsAllocation.js';
import Reports from './mui/dashboard/Dashboard.js';
import Settings from './components/Settings.js'
import { supabase } from './supabaseClient.js';
import Profile from "./media/profile.png"; // Import the image


function App() {
  const { isAuthenticated, setIsAuthenticated } = useContext(DataContext);
  const [mode, setMode] = useState(localStorage.getItem('mode') || 'runRecord');
  const [userEmail, setUserEmail] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);


  useEffect(() => {
    const getUserEmail = async () => {
      if (isAuthenticated) {
        const { data: { user } } = await supabase.auth.getUser();
        if (user) {
          setUserEmail(user.email);
        }
      }
    };
    getUserEmail();
  }, [isAuthenticated, setUserEmail]);

  useEffect(() => {
    localStorage.setItem('mode', mode);
  }, [mode]);

  const handleModeChange = (newMode) => {
    setMode(newMode);
  };

  const renderContent = () => {
    if (mode === 'runRecord') {
      return (
        <div className='Window-content'>
          <InputRun />
          <RunLogs />
        </div>
      );
    }
    else if (mode === 'allocationLog') {
      return (
        <div className='Window-content'>
          <InputAllocation />
          <AllocationLogs />
        </div>
      );
    }
    else if (mode === 'inventory') {
      return (
        <div className='Window-content'>
          <Inventory />
        </div>
      );
    }
    else if (mode === 'feedback') {
      return (
        <div className='Window-content'>
          <InputRequest />
          <RequestBoard />
        </div>
      );
    }
    else if (mode === 'reports') {
      return (
        <div className='Window-content'>
          <Reports />
        </div>
      );
    }
    else if (mode === 'topub') {
      return (
        <div className='Window-content'>
          <ToPubAllocation />
        </div>
      );
    }
    else if (mode === 'events') {
      return (
        <div className='Window-content'>
          <EventsAllocation />
        </div>
      );
    }
    else if (mode === 'settings') {
      return (
        <div className='Window-content'>
          <Settings />
        </div>
      );
    }
    else if (mode === 'distroallo') {
      return (
        <div className='Window-content'>
          <DistroAllo />
        </div>
      );
    }
    else {
      return (
        <div className='Window-content'>
          <Brew />
        </div>
      );
    }
  };

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Error logging out:', error);
    } else {
      setIsAuthenticated(false);
      setIsDropdownOpen(false);
    }
  };

  const toggleProfileDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const renderAll = () => {
    if (!isAuthenticated) {
      return <Login />;
    }
    else {
      return (
        <div className="content">
          <NavBar activeMode={mode} onModeChange={handleModeChange} />
          <div className='Window'>
            <header className="App-header">
              <h4 className='title'>
                {(mode === 'runRecord' && 'Run Records') || (mode === 'allocationLog' && 'Allocation Log') || (mode === 'inventory' && 'Inventory') || (mode === 'brew' && 'Brew') || (mode === 'feedback' && 'Feedback') || (mode === 'reports' && 'Reports') || (mode === 'topub' && '2 Pub') || (mode === 'events' && 'Events & Employees') || (mode === 'settings' && 'Settings') || (mode === 'distroallo' && 'Distro Allo')}
              </h4>
              <div className='user'>
                <p>{userEmail}</p>
                <div className="profile-dropdown">
                  <img
                    src={Profile}
                    className="Profile"
                    alt="Profile"
                    onClick={toggleProfileDropdown}
                  />
                  {isDropdownOpen && (
                    <div className="dropdown-content">
                      <button onClick={handleLogout}>Logout</button>
                    </div>
                  )}
                </div>
              </div>
            </header>
            {renderContent()}
          </div>
        </div>
      );
    }
  };

  return (
    <div className='App'>
      {renderAll()}
    </div>
  );
}

export default App;
