import React, { useRef, useContext, useState, useEffect } from 'react';
import { DataContext } from '../contexts/DataContext';
import { supabase } from '../supabaseClient';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function AllocationLogs() {
    const { allocationLogs, setAllocationLogs, beerBrands, distributors } = useContext(DataContext);
    const [editStates, setEditStates] = useState({});
    const [collapsedMonths, setCollapsedMonths] = useState({});
    const [filters, setFilters] = useState({ dateRange: [null, null], beerBrand: '', distributor: '' });
    const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'descending' });
    const dropdownRef = useRef(null);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [availableYears, setAvailableYears] = useState([]);


    const columnConfig = {
        date: 'Date',
        beerbrand: 'Beer Brand',
        distributor: 'Distributor',
        casesallocated: 'Cases Allocated',
        halfbarrelsallocated: 'Half Barrels Allocated',
        quarterbarrelsallocated: 'Quarter Barrels Allocated',
        sixthbarrelsallocated: 'Sixth Barrels Allocated',
    };

    useEffect(() => {
        const filteredLogs = allocationLogs.map(log => ({
            ...log,
            date: new Date(log.date),
        }));

        setEditStates(filteredLogs.reduce((acc, log) => {
            acc[log.allocationid] = { ...log, isEditing: {}, isSelected: {} };
            return acc;
        }, {}));

        // Calculate available years
        const years = [...new Set(filteredLogs.map(log => log.date.getFullYear()))];
        setAvailableYears(years.sort((a, b) => b - a));

        // Set current year to the most recent year if not already set
        if (!currentYear || !years.includes(currentYear)) {
            setCurrentYear(years[0] || new Date().getFullYear());
        }
    }, [allocationLogs, currentYear]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                const newState = { ...editStates };
                Object.keys(newState).forEach(logId => {
                    newState[logId].isEditing.beerbrand = false;
                    newState[logId].isEditing.distributor = false;
                });
                setEditStates(newState);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [editStates]);

    useEffect(() => {
        setFilters(prev => ({ ...prev, dateRange: [null, null] }));
    }, [currentYear]);

    const trueDate = (date) => {
        var trueDate = new Date(date);
        var userTimezoneOffset = trueDate.getTimezoneOffset() * 60000;
        trueDate = new Date(trueDate.getTime() + userTimezoneOffset)
        return trueDate;
    }

    const applyFiltersAndSort = (logs) => {
        let filteredLogs = logs.filter(log => new Date(log.date).getFullYear() === currentYear);

        if (filters.dateRange[0] && filters.dateRange[1]) {
            filteredLogs = filteredLogs.filter(log => {
                const logDate = new Date(log.date);
                return logDate >= filters.dateRange[0] && logDate <= filters.dateRange[1];
            });
        }

        if (filters.beerBrand) {
            filteredLogs = filteredLogs.filter(log => log.beerbrandid === parseInt(filters.beerBrand));
        }

        if (filters.distributor) {
            filteredLogs = filteredLogs.filter(log => log.distributorid === parseInt(filters.distributor));
        }

        if (sortConfig.key) {
            filteredLogs.sort((a, b) => {
                if (sortConfig.key === 'date') {
                    const dateComparison = new Date(a.date) - new Date(b.date);
                    if (dateComparison !== 0) {
                        return sortConfig.direction === 'ascending' ? dateComparison : -dateComparison;
                    }
                    return sortConfig.direction === 'ascending' ?
                        (a.allocationid - b.allocationid) :
                        (b.allocationid - a.allocationid);
                }
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }

        return filteredLogs;
    };

    const toggleEdit = (logId, field) => {
        const newState = { ...editStates };
        if (field === 'beerbrand' || field === 'distributor') {
            Object.keys(newState).forEach(id => {
                newState[id].isEditing.beerbrand = false;
                newState[id].isEditing.distributor = false;
            });
            newState[logId].isEditing[field] = true;
        } else {
            newState[logId].isEditing[field] = !newState[logId].isEditing[field];
        }
        setEditStates(newState);
    };

    const toggleSelect = (logId, field) => {
        const newState = { ...editStates };
        Object.keys(newState).forEach(id => {
            Object.keys(newState[id].isSelected).forEach(f => {
                newState[id].isSelected[f] = false;
            });
        });
        newState[logId].isSelected[field] = true;
        setEditStates(newState);
    };

    const handleChange = async (value, logId, field) => {
        const newState = { ...editStates };
        if (field === 'date') {
            const localDate = new Date(value.getTime() - value.getTimezoneOffset() * 60000);
            newState[logId][field] = localDate;
        } else if (field === 'beerbrand' || field === 'distributor') {
            const foundItem = (field === 'beerbrand' ? beerBrands : distributors).find(item => item[`${field}id`].toString() === value.toString());
            newState[logId][field] = foundItem;
            newState[logId][`${field}id`] = value;
        } else {
            newState[logId][field] = value;
        }
        setEditStates(newState);
        if (field === 'beerbrand' || field === 'distributor' || field === 'date') {
            await handleBlur(logId, field);
        }
    };

    const handleBlur = async (logId, field) => {
        const log = editStates[logId];
        let updatedValue = log[field];
        let updateField = field;

        if (field === 'date') {
            updatedValue = log[field].toISOString().split('T')[0];
        } else if (field === 'beerbrand') {
            updateField = 'beerbrandid';
            updatedValue = log[field][`${field}id`];
        } else if (field === 'distributor') {
            updateField = 'distributorid';
            updatedValue = log[field][`${field}id`];
        }

        const { error } = await supabase.from('allocationlog').update({ [updateField]: updatedValue }).match({ allocationid: logId });
        if (!error) {
            toggleEdit(logId, field);

            setAllocationLogs(prevLogs => {
                const updatedLogs = prevLogs.map(l => {
                    if (l.allocationid === logId) {
                        if (field === 'beerbrand') {
                            const foundBrand = beerBrands.find(b => b.beerbrandid.toString() === updatedValue.toString());
                            return {
                                ...l,
                                beerbrandid: updatedValue,
                                beerbrand: foundBrand ? { brandname: foundBrand.brandname } : { brandname: 'Unknown' }
                            };
                        } else if (field === 'distributor') {
                            const foundDistributor = distributors.find(d => d.distributorid.toString() === updatedValue.toString());
                            return {
                                ...l,
                                distributorid: updatedValue,
                                distributor: foundDistributor ? { distributorname: foundDistributor.distributorname } : { distributorname: 'Unknown' }
                            };
                        } else {
                            return { ...l, [field]: updatedValue };
                        }
                    }
                    return l;
                });
                return updatedLogs;
            });

            setEditStates(prevStates => {
                const updatedState = {
                    ...prevStates,
                    [logId]: {
                        ...prevStates[logId],
                        [field]: {
                            brandname: beerBrands.find(b => b.beerbrandid.toString() === updatedValue.toString())?.brandname || 'Unknown',
                            distributorname: distributors.find(d => d.distributorid.toString() === updatedValue.toString())?.distributorname || 'Unknown',
                        },
                        [updateField]: updatedValue,
                    }
                };
                return updatedState;
            });
        } else {
            console.error('Error updating log', error);
        }
    };

    const handleKeyPress = async (e, logId, field) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            await handleBlur(logId, field);
        }
    };

    const deleteLog = async (logId) => {
        if (window.confirm("Are you sure you want to delete this log?")) {
            const { error } = await supabase.from('allocationlog').delete().match({ allocationid: logId });
            if (!error) {
                setAllocationLogs(allocationLogs.filter(log => log.allocationid !== logId));
            } else {
                console.error('Error deleting log', error);
            }
        }
    };

    const renderLabelRow = () => (
        <div className='label-allo-row'>
            <div className="delete-cell"></div>
            {Object.keys(columnConfig).map(field => (
                <div key={field} className="log-cell label-cell">
                    <p className="log-content ellipsis">{columnConfig[field]}</p>
                </div>
            ))}
        </div>
    );

    const renderYearNavigation = () => (
        <div className="year-navigation">
            <button onClick={() => setCurrentYear(prev => Math.max(...availableYears.filter(year => year < prev)))}>
                Previous Year
            </button>
            <span>{currentYear}</span>
            <button onClick={() => setCurrentYear(prev => Math.min(...availableYears.filter(year => year > prev)))}>
                Next Year
            </button>
        </div>
    );

    const groupLogsByMonth = (logs) => {
        return logs.reduce((groups, log) => {
            var date = trueDate(log.date);
            if (date.getFullYear() === currentYear) {
                const monthYear = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
                if (!groups[monthYear]) {
                    groups[monthYear] = [];
                }
                groups[monthYear].push(log);
            }
            return groups;
        }, {});
    };

    const toggleMonth = (monthYear) => {
        setCollapsedMonths(prev => ({
            ...prev,
            [monthYear]: !prev[monthYear]
        }));
    };

    const renderLogRow = (log, index, logs) => {
        const currentDate = new Date(log.date).toISOString().split('T')[0];
        const prevDate = index > 0 ? new Date(logs[index - 1].date).toISOString().split('T')[0] : null;

        return (
            <React.Fragment key={log.allocationid}>
                {(index === 0 || currentDate !== prevDate) && (
                    <div className="date-separator">
                        <hr />
                    </div>
                )}
                <div className='allo-row'>
                    <div className="delete-cell" onClick={() => deleteLog(log.allocationid)}>X</div>
                    {Object.keys(columnConfig).map(field => editStates[log.allocationid]?.isEditing[field] ? (
                        field === 'date' ? (
                            <div className='date-picker' key={field}>
                                <DatePicker
                                    selected={new Date(editStates[log.allocationid][field].getTime() + editStates[log.allocationid][field].getTimezoneOffset() * 60000)}
                                    onChange={(date) => handleChange(date, log.allocationid, field)}
                                    onBlur={() => handleBlur(log.allocationid, field)}
                                    onKeyPress={(e) => handleKeyPress(e, log.allocationid, field)}
                                    autoFocus
                                    popperPlacement="top-end"
                                    className="date-picker"
                                    dateFormat="yyyy-MM-dd"
                                />
                            </div>
                        ) : field === 'beerbrand' || field === 'distributor' ? (
                            <div ref={dropdownRef} key={field}>
                                <select
                                    value={editStates[log.allocationid][`${field}id`] || ''}
                                    onChange={(e) => handleChange(e.target.value, log.allocationid, field)}
                                    onBlur={() => handleBlur(log.allocationid, field)}
                                    onKeyPress={(e) => handleKeyPress(e, log.allocationid, field)}
                                    autoFocus
                                    className={`${field}-dropdown`}
                                >
                                    <option value="">Select a {field === 'beerbrand' ? 'Brand' : 'Distributor'}</option>
                                    {(field === 'beerbrand' ? beerBrands : distributors).map((item) => (
                                        <option key={item[`${field}id`]} value={item[`${field}id`]}>
                                            {item[field === 'beerbrand' ? 'brandname' : 'distributorname']}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        ) : (
                            <input
                                key={field}
                                className='log-input'
                                type="text"
                                value={editStates[log.allocationid][field]}
                                onChange={(e) => handleChange(e.target.value, log.allocationid, field)}
                                onBlur={() => handleBlur(log.allocationid, field)}
                                onKeyPress={(e) => handleKeyPress(e, log.allocationid, field)}
                                autoFocus
                            />
                        )
                    ) : (
                        <p
                            key={field}
                            className={`log-cell ellipsis ${editStates[log.allocationid]?.isSelected[field] ? 'selected' : ''}`}
                            style={field === 'distributor' && log.distributor?.distributorname === '2 Pub' ? { backgroundColor: 'rgba(189, 21, 21, 0.2)' } : {}}
                            onClick={() => toggleSelect(log.allocationid, field)}
                            onDoubleClick={() => toggleEdit(log.allocationid, field)}
                        >
                            {field === 'date'
                                ? (log[field] instanceof Date
                                    ? new Date(log[field].getTime() - log[field].getTimezoneOffset() * 60000).toISOString().split('T')[0]
                                    : typeof log[field] === 'string'
                                        ? log[field].split('T')[0]
                                        : 'Invalid Date')
                                : field === 'beerbrand'
                                    ? (beerBrands.find(b => b.beerbrandid === log.beerbrandid)?.brandname || 'Unknown')
                                    : field === 'distributor'
                                        ? (distributors.find(d => d.distributorid === log.distributorid)?.distributorname || 'Unknown')
                                        : log[field]}
                        </p>
                    ))}
                </div>
            </React.Fragment>
        );
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const handleDateRangeChange = (dates) => {
        const [start, end] = dates;
        setFilters({ ...filters, dateRange: [start, end] });
    };

    const handleSortChange = (key) => {
        let direction = 'descending';
        if (sortConfig.key === key && sortConfig.direction === 'descending') {
            direction = 'ascending';
        }
        setSortConfig({ key, direction });
    };

    const groupedLogs = groupLogsByMonth(applyFiltersAndSort(allocationLogs));

    return (
        <div className='Logs' style={{ overflowX: 'auto' }}>
            {renderYearNavigation()}
            <div className="filters">
                <DatePicker
                    selectsRange
                    startDate={filters.dateRange[0]}
                    endDate={filters.dateRange[1]}
                    onChange={handleDateRangeChange}
                    isClearable={true}
                    placeholderText="Select a date range"
                />
                <select name="beerBrand" value={filters.beerBrand} onChange={handleFilterChange}>
                    <option value="">All Brands</option>
                    {beerBrands.map((brand) => (
                        <option key={brand.beerbrandid} value={brand.beerbrandid}>
                            {brand.brandname}
                        </option>
                    ))}
                </select>
                <select name="distributor" value={filters.distributor} onChange={handleFilterChange}>
                    <option value="">All Distributors</option>
                    {distributors.map((dist) => (
                        <option key={dist.distributorid} value={dist.distributorid}>
                            {dist.distributorname}
                        </option>
                    ))}
                </select>
                <button onClick={() => handleSortChange('date')}>Sort by Date</button>
                <button onClick={() => handleSortChange('casesallocated')}>Sort by Cases Allocated</button>
            </div>
            <div className="log-container">
                {renderLabelRow()}
                {Object.entries(groupedLogs).map(([monthYear, logs]) => (
                    <div key={monthYear} className="month-group">
                        <div
                            className="month-header"
                            onClick={() => toggleMonth(monthYear)}
                        >
                            <span>{collapsedMonths[monthYear] ? '▼' : '▲'}</span>
                            <p>{trueDate(monthYear).toLocaleString('default', { month: 'long', year: 'numeric' })}</p>
                        </div>
                        {!collapsedMonths[monthYear] && logs.map((log, index) => renderLogRow(log, index, logs))}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AllocationLogs;
