import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import OrderForm from './OrderForm.js'; // Import the OrderForm component


function DistroAllo() {
    const [activeBatches, setActiveBatches] = useState([]);
    const [totalRunSheets, setTotalRunSheets] = useState([]);
    const [beerBrands, setBeerBrands] = useState({});
    const [distributors, setDistributors] = useState({});
    const [error, setError] = useState(null);
    const [newRunSheetDate, setNewRunSheetDate] = useState('');
    const [selectedBrand, setSelectedBrand] = useState('');
    const [batchInput, setBatchInput] = useState('');
    const [enteredBatches, setEnteredBatches] = useState([]);
    const [selectedRun, setSelectedRun] = useState(null);
    const [creatingRunSheet, setCreatingRunSheet] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editableRun, setEditableRun] = useState(null);
    const [distributorRows, setDistributorRows] = useState([
        { distributor: '', cases: 0, halfBBL: 0, quarterBBL: 0, sixthBBL: 0, notes: '' }
    ]);
    const [view, setView] = useState('sheets'); // Default to 'sheets'

    const [trueYield, setTrueYield] = useState(0);

    useEffect(() => {
        fetchDistroRows();
    }, [selectedRun]);


    useEffect(() => {
        fetchRecentBatchesAndBrands();
    }, []);

    const fetchRecentBatchesAndBrands = async () => {
        try {
            const { data: batches, error: batchError } = await supabase
                .from('batch')
                .select('*')
                .order('estimatedpackagingdate', { ascending: true });

            if (batchError) throw batchError;

            const { data: runSheets, error: runSheetsError } = await supabase
                .from('distroallo')
                .select('*');

            if (runSheetsError) throw runSheetsError;


            const { data: brands, error: brandError } = await supabase
                .from('beerbrands')
                .select('*');

            if (brandError) throw brandError;

            const brandMap = {};
            brands.forEach((brand) => {
                brandMap[brand.beerbrandid] = brand.brandname;
            });

            const { data: distributors, error: distributorsError } = await supabase
                .from('distributors')
                .select('*');

            if (distributorsError) throw distributorsError;

            const distributorsMap = {};
            distributors.forEach((distributor) => {
                distributorsMap[distributor.distributorid] = distributor.distributorname;
            });

            setActiveBatches(batches);
            setBeerBrands(brandMap);
            setDistributors(distributorsMap);
            setTotalRunSheets(runSheets);
        } catch (error) {
            console.error('Error fetching active batches or beer brands:', error);
            setError('Failed to load active batches');
        }
    };

    const fetchDistroRows = async () => {
        if (!selectedRun) {
            setDistributorRows([]);
            return;
        }

        try {
            let { data: distroRows, error } = await supabase
                .from('distroallorows')
                .select('*')
                .eq('run_id', selectedRun.id);

            if (error) throw error;

            setDistributorRows(distroRows);
        } catch (error) {
            console.error('Failed to fetch distribution rows:', error);
            setError('Failed to load distribution rows');
        }
    };

    const handleTabClick = (tab) => {
        setView(tab);
    };

    useEffect(() => {
        if (!selectedRun) {
            setTrueYield(0);
            return;
        }

        const calculateTrueYield = async () => {
            try {
                let totalYield = 0;
                for (const batchId of selectedRun.batches) {
                    const { data, error } = await supabase
                        .from('batch')
                        .select('yield')
                        .eq('batchid', batchId)
                        .single();

                    if (error) {
                        console.error(`Error fetching yield for batch ${batchId}:`, error);
                        continue;
                    }

                    totalYield += data.yield || 0;
                }

                setTrueYield(totalYield);
            } catch (error) {
                console.error('Failed to calculate true yield:', error);
                setTrueYield(0);
            }
        };

        calculateTrueYield();
    }, [selectedRun, supabase]);


    const handleDeleteBatch = (index) => {
        setEnteredBatches(current => current.filter((_, idx) => idx !== index));
    };

    const handleRowChange = (index, field, value) => {
        let updatedRows = [...distributorRows];
        updatedRows[index] = { ...updatedRows[index], [field]: value };
        setDistributorRows(updatedRows);
        // Pass the row, field, and value explicitly
        updateDistributorRow(updatedRows[index], field, value);
    };

    const updateDistributorRow = async (row, field, value) => {
        const { data, error } = await supabase
            .from('distroallorows')
            .update({ [field]: value }) // Use the field and value passed to the function
            .eq('id', row.id);

        if (error) {
            console.error('Error updating row:', error);
        }
    };

    const handleAddNewDistroRow = async () => {
        // Define a new row with default values
        const newRow = {
            run_id: selectedRun.id,
            distributor: null,  // Assuming 'distributor' needs an initial value or ID
            cases: 0,
            halves: 0,
            quarters: 0,
            sixths: 0,
            notes: ''
        };


        try {
            // Insert the new row into the database
            const { data, error } = await supabase
                .from('distroallorows')
                .insert(newRow);

            if (error) throw error;

            // Add the new row to the state to update UI
            setDistributorRows([...distributorRows, data[0]]);
            fetchDistroRows();
        } catch (error) {
            console.error('Failed to add new distribution row:', error);
            fetchDistroRows();

        }
    };

    const handleDeleteDistributorRow = async (rowId, index) => {
        try {
            const { error } = await supabase
                .from('distroallorows')
                .delete()
                .eq('id', rowId);

            if (error) throw error;

            // Remove the row from the local state
            setDistributorRows(currentRows => currentRows.filter((_, idx) => idx !== index));
        } catch (error) {
            console.error('Error deleting distribution row:', error);
        }
    };

    const handleAddBatch = () => {
        // Split input by spaces, commas, and newlines, filter out empty entries
        const batchIds = batchInput.split(/[\s,]+/).filter(Boolean);
        const newBatches = batchIds.map(id => {
            const batch = activeBatches.find(b => b.batchid.toString() === id);
            if (batch) {
                return { batchid: batch.batchid, brandname: beerBrands[batch.beerbrandid], estimatedpackagingdate: batch.estimatedpackagingdate };
            }
            return { batchid: id, brandname: "Not Found", estimatedpackagingdate: "N/A" };
        });
        setEnteredBatches([...enteredBatches, ...newBatches]);
        setBatchInput(''); // Clear the input after adding
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ',' || e.key === ' ') {
            e.preventDefault();
            handleAddBatch();
        }
    };

    const handleCreateOrUpdateRunSheet = async (event) => {
        event.preventDefault();
        const payload = {
            date: newRunSheetDate,
            brand: selectedBrand,
            batches: enteredBatches.map(batch => batch.batchid)
        };
        try {
            let result;
            if (isEditing && editableRun) {
                result = await supabase
                    .from('distroallo')
                    .update(payload)
                    .match({ id: editableRun.id });
            } else {
                result = await supabase
                    .from('distroallo')
                    .insert([payload]);
            }
            const { data, error } = result;
            if (error) throw error;

            setIsEditing(false);
            setEditableRun(null);
            // Resetting fields and updating UI states as necessary
            setNewRunSheetDate('');
            setSelectedBrand('');
            setEnteredBatches([]);
            setCreatingRunSheet(false);
            fetchRecentBatchesAndBrands();
        } catch (error) {
            console.error('Error submitting run sheet:', error);
            alert('Failed to process run sheet');
        }
    };

    const handleDeleteRun = async (runId) => {
        try {
            // Start transaction to delete both the run and its related rows
            const { error: deleteRowsError } = await supabase
                .from('distroallorows')
                .delete()
                .eq('run_id', runId);

            if (deleteRowsError) throw deleteRowsError;

            const { error: deleteRunError } = await supabase
                .from('distroallo')
                .delete()
                .eq('id', runId);

            if (deleteRunError) throw deleteRunError;

            // Update UI state
            setTotalRunSheets(currentSheets => currentSheets.filter(run => run.id !== runId));
            if (selectedRun && selectedRun.id === runId) {
                setSelectedRun(null); // Clear selected run if it's the deleted one
            }
        } catch (error) {
            console.error('Error deleting run:', error);
        }
    };

    // Function to generate HTML for distributorRows with improved column alignment
    const generateDistributorRowsHtml = () => {
        const headerHtml = `
        <div class="row header" style="background-color: #f46524;">
            <div class="cell">${beerBrands[selectedRun.brand] || 'Unknown Brand'}</div>
            <div class="cell">${selectedRun.date || 'No Date'}</div>
        </div>
        <div class="row header" style="background-color: #f46524;">
            <div class="distro">Distributor</div>
            <div class="cell">Cases</div>
            <div class="cell">Halves</div>
            <div class="cell">Quarters</div>
            <div class="cell">Sixths</div>
            <div class="cell">Notes</div>
        </div>
    `;
        const bodyHtml = distributorRows.map((row, index) => `
        <div class="row" style="background-color: ${index % 2 === 0 ? '#ffe6dd' : 'white'};">
            <div class="distro"><span class="distro-text">${distributors[row.distributor] || 'MISSING'}</span></div>            
            <div class="cell">${row.cases}</div>
            <div class="cell">${row.halves}</div>
            <div class="cell">${row.quarters}</div>
            <div class="cell">${row.sixths}</div>
            <div class="cell notes">${row.notes}</div>
        </div>
    `).join('');

        // Calculate totals for Cases, Halves, Quarters, Sixths
        const totalCases = distributorRows.reduce((acc, curr) => acc + (Number(curr.cases) || 0), 0);
        const totalHalves = distributorRows.reduce((acc, curr) => acc + (Number(curr.halves) || 0), 0);
        const totalQuarters = distributorRows.reduce((acc, curr) => acc + (Number(curr.quarters) || 0), 0);
        const totalSixths = distributorRows.reduce((acc, curr) => acc + (Number(curr.sixths) || 0), 0);

        const totalsHtml = `
        <div class="row">
            <div class="cell"> -- </div>
        </div>
        <div class="row totals">
            <div class="distro">Total</div>
            <div class="cell">${totalCases}</div>
            <div class="cell">${totalHalves}</div>
            <div class="cell">${totalQuarters}</div>
            <div class="cell">${totalSixths}</div>
            <div class="cell notes"></div>
        </div>
    `;
        const totalBblsHtml = `
        <div class="row totals">
            <div class="cell distro">bbl</div>
            <div class="cell">${casesToBarrels.toFixed(2)}</div>
            <div class="cell">${halfBBLsToBarrels.toFixed(2)}</div>
            <div class="cell">${quarterBBLsToBarrels.toFixed(2)}</div>
            <div class="cell">${sixthBBLsToBarrels.toFixed(2)}</div>
            <div class="cell notes"></div>
        </div>
    `;
        const statsHtml = `
        <div class="row totals">
            <div class="cell distro">Total bbls</div>
            <div class="cell">${totalBarrels.toFixed(2)}</div>
            <div class="cell distro">Can Pallets</div>
            <div class="cell">${canPallets.toFixed(2)}</div>
            <div class="cell">Runtime</div>
            <div class="cell">${runtimeHours.toFixed(2)} Hrs</div>
        </div>
    `;

        return headerHtml + bodyHtml + totalsHtml + totalBblsHtml + statsHtml;
    }

    // Function to handle print
    const handlePrint = () => {
        const distributorRowsHtml = generateDistributorRowsHtml();
        const windowPrint = window.open('', '_blank', 'width=800,height=600');
        windowPrint.document.write(`
    <html>
        <head>
            <title>Print</title>
            <style>
                @page { size: landscape; }
                body { font-family: Arial, sans-serif; font-size: 28px; font-weight: bold; }
                .row { display: flex;}
                .cell { flex: 1; padding: 0; border: 1px solid #ccc; text-align: center; overflow: hidden; }
                .notes { flex: 1; font-size:16px} // Notes column wider
                .distro { flex: 1; text-align: left;  }
                .distro { flex: 1; text-align: left; padding: 0; border: 1px solid #ccc; overflow: hidden; white-space: nowrap; }
            </style>
        </head>
        <body>
            ${distributorRowsHtml}
        </body>
    </html>
    `);
        windowPrint.document.close();
        windowPrint.focus();
        setTimeout(() => {
            windowPrint.print();
            windowPrint.close();
        }, 1000);
    };

    const handleEditRun = (run) => {
        setIsEditing(true);
        setEditableRun(run);
        setNewRunSheetDate(run.date);
        setSelectedBrand(run.brand);
        // Convert batch IDs back to entered batches structure
        const batchDetails = run.batches.map(batchId => {
            const batch = activeBatches.find(b => b.batchid === batchId);
            return batch ? {
                batchid: batch.batchid,
                brandname: beerBrands[batch.beerbrandid] || 'Unknown',
                estimatedpackagingdate: batch.estimatedpackagingdate || 'N/A'
            } : { batchid: batchId, brandname: "Not Found", estimatedpackagingdate: "N/A" };
        });
        setEnteredBatches(batchDetails);
    };

    // Calculate totals for cases and barrels
    const totalCases = distributorRows.reduce((total, row) => total + Number(row.cases), 0);
    const totalHalfBBLs = distributorRows.reduce((total, row) => total + Number(row.halves), 0);
    const totalQuarterBBLs = distributorRows.reduce((total, row) => total + Number(row.quarters), 0);
    const totalSixthBBLs = distributorRows.reduce((total, row) => total + Number(row.sixths), 0);

    // Convert total cases to barrels (1 BBL = 13.33 cases)
    const casesToBarrels = totalCases / 13.33;

    // Convert 1/2 BBLs, 1/4 BBLs, 1/6 BBLs to barrels
    const halfBBLsToBarrels = totalHalfBBLs / 2; // 2 half BBLs = 1 full BBL
    const quarterBBLsToBarrels = totalQuarterBBLs / 4; // 4 quarter BBLs = 1 full BBL
    const sixthBBLsToBarrels = totalSixthBBLs / 6; // 6 sixth BBLs = 1 full BBL

    // Total barrels (cases converted + other barrel types)
    const totalBarrels = casesToBarrels + halfBBLsToBarrels + quarterBBLsToBarrels + sixthBBLsToBarrels;

    // Can Pallets calculation
    const canPallets = (totalCases * 24) / 8169;

    // Runtime calculation in hours
    const runtimeHours = casesToBarrels / 16;

    // Update the totaledBarrels value
    const totaledBarrels = totalBarrels;

    return (
        <div>
            <div className="tabs">
                <button onClick={() => handleTabClick('sheets')} className={view === 'sheets' ? 'active' : ''}>Run Sheets</button>
                <button onClick={() => handleTabClick('orderform')} className={view === 'orderform' ? 'active' : ''}>Order Form</button>
            </div>
            {view === 'sheets' && (
                <div className='distro-allo-container'>
                    <div className="left-section">
                        <div className='distro-allo-right-top'>
                            <div className='run-sheet-header'>
                                <h2>Run Sheets</h2>
                                <button onClick={() => setCreatingRunSheet(true)}>+</button>
                            </div>

                            {totalRunSheets.length === 0 ? (
                                <p>No active batches available</p>
                            ) : (
                                <div className="run-sheets-list">
                                    {totalRunSheets
                                        .slice() // Create a copy to avoid mutating the original array
                                        .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sorting by date
                                        .map((run) => (
                                            <div className='run-sheet-list-inner'>
                                                <button onClick={() => handleDeleteRun(run.id)} style={{ marginLeft: '10px' }}>x</button>
                                                <button
                                                    key={run.id}
                                                    className={`run-button ${selectedRun && selectedRun.id === run.id ? 'selected' : ''}`}
                                                    onClick={() => setSelectedRun(run)}
                                                >
                                                    {run.date} {beerBrands[run.brand] ? beerBrands[run.brand].substring(0, 9) : 'Unknown'}
                                                </button>
                                                <button onClick={() => handleEditRun(run)} style={{ marginLeft: '10px' }}>Edit</button>
                                            </div>
                                        ))}
                                </div>
                            )}

                        </div>
                        <div className="left-section">
                            {creatingRunSheet || isEditing ? (
                                <div>
                                    <h2>{isEditing ? 'Edit Run Sheet' : 'Create New Run Sheet'}</h2>
                                    <form onSubmit={handleCreateOrUpdateRunSheet}>
                                        <div className="form-group">
                                            <label>Date:</label>
                                            <input
                                                type="date"
                                                value={newRunSheetDate}
                                                onChange={(e) => setNewRunSheetDate(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Brand:</label>
                                            <select
                                                value={selectedBrand}
                                                onChange={(e) => setSelectedBrand(e.target.value)}
                                                required
                                            >
                                                <option value="">Select Brand</option>
                                                {Object.entries(beerBrands).map(([id, name]) => (
                                                    <option key={id} value={id}>{name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                value={batchInput}
                                                placeholder="Enter batch IDs separated by space, comma, or enter"
                                                onChange={e => setBatchInput(e.target.value)}
                                                onKeyDown={handleKeyDown}
                                                onBlur={handleAddBatch}
                                            />

                                        </div>
                                        <div>
                                            {enteredBatches.map((batch, index) => (
                                                <div key={index} style={{ margin: '10px 0' }}>
                                                    <span>Batch ID: {batch.batchid}, Brand: {batch.brandname}, Date: {batch.estimatedpackagingdate}</span>
                                                    <button onClick={() => handleDeleteBatch(index)} style={{ marginLeft: '10px' }}>Delete</button>
                                                </div>
                                            ))}
                                        </div>
                                        <button type="submit">Confirm</button>
                                        <button type="button" onClick={() => { setCreatingRunSheet(false); setIsEditing(false); }}>Cancel</button>
                                    </form>
                                </div>
                            ) : (
                                <>
                                    <div className="batch-details">
                                        {selectedRun ? (
                                            selectedRun.batches && selectedRun.batches.length > 0 ? (
                                                <div>
                                                    <div key={selectedRun.id}>
                                                        <h4>{beerBrands[selectedRun.brand]} - {selectedRun.date}</h4>
                                                    </div>
                                                    {selectedRun.batches.map(batchId => {
                                                        const batch = activeBatches.find(b => b.batchid === batchId);
                                                        return batch ? (
                                                            <div key={batchId}>
                                                                <h4>Batch {batch.batchid} ({beerBrands[batch.beerbrandid] || 'Unknown Brand'}) </h4>
                                                                <p>Fermenter: FV{batch.fermentationvesselid || "Missing FV"}</p>
                                                                <p>True Yield: {batch.yield || 'No Yield Yet'}</p>
                                                                <p>Brew Date: {batch.brewdate || 'Missing'}</p>
                                                                <p>Crash Date: {batch.crashdate || 'Missing'}</p>
                                                                <p>Packaging Date: {batch.estimatedpackagingdate || 'Missing'}</p>
                                                            </div>
                                                        ) : (
                                                            <p key={batchId}>Batch ID {batchId} not found</p>
                                                        );
                                                    })}
                                                </div>
                                            ) : (
                                                <div key={selectedRun.id}>
                                                    <h4>{beerBrands[selectedRun.brand]} - {selectedRun.date}</h4>
                                                </div>
                                            )
                                        ) : (
                                            <p>Please select a batch or Run Sheet</p>
                                        )}
                                    </div>

                                </>
                            )}
                        </div>
                    </div>

                    <div className="vertical-line"></div>

                    <div className="right-section">
                        {selectedRun ? (

                            <div>
                                <button className="print-button" onClick={handlePrint}>Print</button>
                                <div className="grid-container">
                                    <div className="distro-allo-row">
                                        <div className="cell span-cell">{beerBrands[selectedRun.brand] || 'Unknown Brand'} ({selectedRun.batches.join(', ')})</div>
                                        <div className="cell span-cell">{selectedRun.date}</div>
                                        <div className="cell span-cell">
                                            {totaledBarrels.toFixed(2)}/{trueYield} BBL
                                        </div>
                                    </div>

                                    <div className="distro-allo-row">
                                        <div className="cell">Distributors</div>
                                        <div className="cell">Cases</div>
                                        <div className="cell">1/2 BBLs</div>
                                        <div className="cell">1/4 BBLs</div>
                                        <div className="cell">1/6 BBLs</div>
                                        <div className="bigger-cell">Notes</div>
                                    </div>

                                    {distributorRows.map((row, index) => (
                                        <div className="distro-allo-row" key={row.id}>
                                            <div className="bigger-cell">
                                                <select
                                                    value={row.distributor}
                                                    onChange={(e) => handleRowChange(index, 'distributor', e.target.value)}
                                                    required
                                                >
                                                    <option value="">Select Distributor</option>
                                                    {Object.entries(distributors).map(([id, name]) => (
                                                        <option key={id} value={id}>{name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="cell">
                                                <input
                                                    type="number"
                                                    value={row.cases}
                                                    onChange={(e) => handleRowChange(index, 'cases', e.target.value)}
                                                    placeholder="Cases"
                                                />
                                            </div>
                                            <div className="cell">
                                                <input
                                                    type="number"
                                                    value={row.halves}
                                                    onChange={(e) => handleRowChange(index, 'halves', e.target.value)}
                                                    placeholder="1/2 BBLs"
                                                />
                                            </div>
                                            <div className="cell">
                                                <input
                                                    type="number"
                                                    value={row.quarters}
                                                    onChange={(e) => handleRowChange(index, 'quarters', e.target.value)}
                                                    placeholder="1/4 BBLs"
                                                />
                                            </div>
                                            <div className="cell">
                                                <input
                                                    type="number"
                                                    value={row.sixths}
                                                    onChange={(e) => handleRowChange(index, 'sixths', e.target.value)}
                                                    placeholder="1/6 BBLs"
                                                />
                                            </div>
                                            <div className="bigger-cell">
                                                <div className='notes-and-delete'>
                                                    <input
                                                        type="text"
                                                        value={row.notes}
                                                        onChange={(e) => handleRowChange(index, 'notes', e.target.value)}
                                                        placeholder="Notes"
                                                    />
                                                    <button onClick={() => handleDeleteDistributorRow(row.id, index)}>X</button>
                                                </div>

                                            </div>
                                        </div>
                                    ))}

                                    <div className="distro-allo-row">
                                        <div className="cell add-new-row-cell">
                                            <button onClick={handleAddNewDistroRow}>Add New Row</button>
                                        </div>
                                    </div>

                                    {/* Totals row */}
                                    <div className="distro-allo-row">
                                        <div className="cell">Totals</div>
                                        <div className="cell">{totalCases}</div>
                                        <div className="cell">{totalHalfBBLs}</div>
                                        <div className="cell">{totalQuarterBBLs}</div>
                                        <div className="cell">{totalSixthBBLs}</div>
                                        <div className="cell"></div>
                                    </div>

                                    {/* Total Barrels row */}
                                    <div className="distro-allo-row">
                                        <div className="cell">Total Barrels</div>
                                        <div className="cell">{casesToBarrels.toFixed(2)} BBL</div>
                                        <div className="cell">{halfBBLsToBarrels.toFixed(2)} BBL</div>
                                        <div className="cell">{quarterBBLsToBarrels.toFixed(2)} BBL</div>
                                        <div className="cell">{sixthBBLsToBarrels.toFixed(2)} BBL</div>
                                        <div className="cell"></div>
                                    </div>

                                    {/* Can Pallets and Runtime row */}
                                    <div className="distro-allo-row">
                                        <div className="cell">Can Pallets</div>
                                        <div className="cell">{canPallets.toFixed(2)}</div>
                                        <div className="cell"></div>
                                        <div className="cell"></div>
                                        <div className="cell">Runtime (hours)</div>
                                        <div className="cell">{runtimeHours.toFixed(2)} hrs</div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <p>Please select a batch</p>
                        )}
                    </div>
                </div>
            )}
            {view === 'orderform' && (
                <div>
                    <OrderForm></OrderForm>
                </div>
            )}

        </div>
    );
}

export default DistroAllo;
