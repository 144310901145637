import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { supabase } from '../../supabaseClient';
import { standardizeDateHandling } from '../../utils/dateUtils';
import trash from '../../media/trash.png'

const BrewBriteStatus = ({ brites, selectedBrite, activeBatches, beerBrands, handlePreformRunSubmit, handleDeleteBatch, updateBatchInDB, updateBriteInDB }) => {
    const selectedBriteData = brites.find(brite => brite.britetankids === selectedBrite);
    const selectedBatchData = activeBatches.find(batch => Array.isArray(selectedBriteData.batchid) && selectedBriteData.batchid.includes(batch.batchid));
    const selectedBeerBrandData = beerBrands.find(beerbrand => beerbrand.beerbrandid === selectedBatchData?.beerbrandid);

    const [selectedTab, setSelectedTab] = useState(0); // Tracks the selected batch tab
    const selectedBatches = activeBatches.filter(batch =>
        Array.isArray(selectedBriteData.batchid) && selectedBriteData.batchid.includes(batch.batchid)
    );

    const [localSelectedBatchData, setLocalSelectedBatchData] = useState(selectedBatchData || {});
    const [localSelectedBriteData, setLocalSelectedBriteData] = useState(selectedBriteData || {});
    const [localYields, setLocalYields] = useState({});

    useEffect(() => {
        if (selectedBrite) {
            fetchYields(selectedBrite);
        }
    }, [selectedBrite]);

    const fetchYields = async (briteTankId) => {
        const { data, error } = await supabase
            .from('batchyields')
            .select('*')
            .eq('britetankids', briteTankId);

        if (error) console.error('Error fetching yields', error);
        else setLocalYields(data.reduce((acc, item) => ({ ...acc, [item.batchid]: item.yield }), {}));
    };

    useEffect(() => {
        setLocalSelectedBatchData(selectedBatches[selectedTab] || {});
    }, [selectedBatchData, selectedTab]);

    useEffect(() => {
        setLocalSelectedBriteData(selectedBriteData || {});
    }, [selectedBriteData]);

    const updateBatchData = (key, value, shouldUpdateDB = false) => {
        const updatedBatchData = { ...localSelectedBatchData, [key]: value };
        setLocalSelectedBatchData(updatedBatchData);

        if (shouldUpdateDB) {
            updateBatchInDB(updatedBatchData);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    const updateBriteData = (key, value, shouldUpdateDB = false) => {
        const updatedBriteData = { ...localSelectedBriteData, [key]: value };
        setLocalSelectedBriteData(updatedBriteData);

        if (shouldUpdateDB) {
            updateBriteInDB(updatedBriteData);
        }
    };

    const handlePerformRun = (event) => {
        event.preventDefault();
        handlePreformRunSubmit(event);
        console.log(localSelectedBatchData);
        console.log(localSelectedBriteData);
    };

    const updateYieldInDB = async (batchId, briteTankId, newYield) => {
        const { data: existing, error: fetchError } = await supabase
            .from('batchyields')
            .select('batchyieldid')
            .eq('batchid', batchId)
            .eq('britetankids', briteTankId)
            .single();

        let response;
        if (existing) {
            response = await supabase
                .from('batchyields')
                .update({ yield: newYield })
                .eq('batchyieldid', existing.batchyieldid);
        } else {
            response = await supabase
                .from('batchyields')
                .insert([
                    { batchid: batchId, britetankids: briteTankId, yield: newYield }
                ]);
        }

        const { data, error } = response;
        if (error) console.error('Error updating yield:', error);

        const { data: allBatches, error: error2 } = await supabase
            .from('batchyields')
            .select('yield')
            .eq('batchid', batchId);

        if (error2) console.error('Error (2) updating yield:', error2);

        const { data: data3, error: error3 } = await supabase
            .from('batch')
            .update({ yield: allBatches.reduce((b, a) => b + a.yield, 0) })
            .eq('batchid', batchId);

        if (error3) console.error('Error (3) updating yield:', error3);


    };


    const clearDryHopDate = () => {
        const updatedBatchData = { ...localSelectedBatchData, dryhopdate: null };
        setLocalSelectedBatchData(updatedBatchData);
        updateBatchInDB(updatedBatchData);
    };


    if (!selectedBriteData) {
        return <p>No matching brite tank found.</p>;
    }

    return (
        <div>
            <div className='brew-form-header'>
                <button className="trashIcon" type="button" onClick={() => handleDeleteBatch(selectedBriteData.batchid, 'BT', selectedBrite)}>
                    <img src={trash} alt="trash" />
                </button>
                <h3>Status of BT{selectedBrite} ({selectedBriteData?.batchid?.length ? selectedBriteData.batchid.join(', ') : 'No batch ID available'})</h3>
            </div>
            <form onKeyDown={handleKeyDown}>
                <table>
                    <tbody>
                        <tr>
                            <td>Status:</td>
                            <td>{selectedBriteData.status}</td>
                        </tr>
                        <tr>
                            <td>Capacity:</td>
                            <td>{selectedBriteData.capacity}</td>
                        </tr>
                        <tr>
                            <td>Yield:</td>
                            <td>
                                {selectedBatches.length === 1 ? (
                                    <div key={selectedBatches[0].batchid}>
                                        <input
                                            className="small-box"
                                            type="text"
                                            value={localYields[selectedBatches[0].batchid] || ''}
                                            onChange={(e) => setLocalYields({ ...localYields, [selectedBatches[0].batchid]: e.target.value })}
                                            onBlur={(e) => updateYieldInDB(selectedBatches[0].batchid, selectedBrite, e.target.value)}
                                        />
                                    </div>
                                ) : (
                                    <div>
                                        <input
                                            className="small-box"
                                            type="text"
                                            value={
                                                selectedBatches.reduce((total, batch) => {
                                                    return total + Number(localYields[batch.batchid] || 0);
                                                }, 0)
                                            }
                                            onChange={(e) => {
                                                const valuePerBatch = e.target.value / selectedBatches.length;
                                                const newLocalYields = selectedBatches.reduce((acc, batch) => ({
                                                    ...acc,
                                                    [batch.batchid]: valuePerBatch
                                                }), {});
                                                setLocalYields(newLocalYields);
                                            }}
                                            onBlur={() => {
                                                selectedBatches.forEach(batch => {
                                                    updateYieldInDB(batch.batchid, selectedBrite, localYields[batch.batchid] || 0);
                                                });
                                            }}
                                        />
                                    </div>
                                )}
                            </td>

                        </tr>
                        <tr>
                            <td>Brand:</td>
                            <td>
                                <select
                                    value={localSelectedBatchData?.brandid || ''}
                                    onChange={(e) => updateBatchData('beerbrandid', e.target.value, true)}
                                >
                                    {selectedBeerBrandData?.brandname ? (
                                        <option value={selectedBeerBrandData.beerbrandid}>
                                            {selectedBeerBrandData.brandname}
                                        </option>
                                    ) : (
                                        <option value="">Select a brand</option>
                                    )}
                                    {beerBrands.map((brand) => (
                                        <option key={brand.beerbrandid} value={brand.beerbrandid}>
                                            {brand.brandname}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        {selectedBatches.length > 1 && (<tr>
                            <td></td>
                            <td>
                                <div className="batch-tabs">
                                    {selectedBatches.map((batch, index) => (
                                        <button
                                            key={batch.batchid}
                                            type="button"
                                            className={`batch-tab ${index === selectedTab ? 'active' : ''}`}
                                            onClick={() => setSelectedTab(index)}
                                        >
                                            {batch.batchid}
                                        </button>
                                    ))}
                                </div>
                            </td>
                        </tr>)}
                        <tr>
                            <td>Brew Date:</td>
                            <td>
                                <DatePicker
                                    className='medium-box'
                                    selected={localSelectedBatchData.brewdate ? standardizeDateHandling.fromStorageFormat(localSelectedBatchData.brewdate) : null}
                                    onChange={(date) => updateBatchData('brewdate', standardizeDateHandling.toStorageFormat(date), true)}
                                />
                                <input
                                    className='small-box'
                                    type="text"
                                    value={localSelectedBatchData?.brewdateworkerid || ''}
                                    onChange={(e) => updateBatchData('brewdateworkerid', e.target.value)}
                                    onBlur={(e) => updateBatchData('brewdateworkerid', e.target.value, true)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Dry Hop Date:</td>
                            <td>
                                <DatePicker
                                    className='medium-box'
                                    selected={localSelectedBatchData.dryhopdate ? standardizeDateHandling.fromStorageFormat(localSelectedBatchData.dryhopdate) : null}
                                    onChange={(date) => updateBatchData('dryhopdate', standardizeDateHandling.toStorageFormat(date), true)}
                                />
                                <input
                                    className='small-box'
                                    type="text"
                                    value={localSelectedBatchData?.dryhopdateworkerid || ''}
                                    onChange={(e) => updateBatchData('dryhopdateworkerid', e.target.value)}
                                    onBlur={(e) => updateBatchData('dryhopdateworkerid', e.target.value, true)}
                                />
                                <button type="button" onClick={clearDryHopDate}>
                                    x
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>Crash Date:</td>
                            <td>
                                <DatePicker
                                    className='medium-box'
                                    selected={localSelectedBatchData.crashdate ? standardizeDateHandling.fromStorageFormat(localSelectedBatchData.crashdate) : null}
                                    onChange={(date) => updateBatchData('crashdate', standardizeDateHandling.toStorageFormat(date), true)}
                                />
                                <input
                                    className='small-box'
                                    type="text"
                                    value={localSelectedBatchData?.crashdateworkerid || ''}
                                    onChange={(e) => updateBatchData('crashdateworkerid', e.target.value)}
                                    onBlur={(e) => updateBatchData('crashdateworkerid', e.target.value, true)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>O.G.:</td>
                            <td>
                                <input
                                    className='small-box'
                                    type="text"
                                    value={localSelectedBatchData?.originalgravity || ''}
                                    onChange={(e) => updateBatchData('originalgravity', e.target.value)}
                                    onBlur={(e) => updateBatchData('originalgravity', e.target.value, true)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Final Gravity:</td>
                            <td>
                                <input
                                    className='small-box'
                                    type="text"
                                    value={localSelectedBatchData?.finalgravity || ''}
                                    onChange={(e) => updateBatchData('finalgravity', e.target.value)}
                                    onBlur={(e) => updateBatchData('finalgravity', e.target.value, true)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Package Date:</td>
                            <td>
                                <DatePicker
                                    className='medium-box'
                                    selected={localSelectedBatchData.estimatedpackagingdate ? standardizeDateHandling.fromStorageFormat(localSelectedBatchData.estimatedpackagingdate) : null}
                                    onChange={(date) => updateBatchData('estimatedpackagingdate', standardizeDateHandling.toStorageFormat(date), true)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Notes:</td>
                            <td>
                                <textarea
                                    value={localSelectedBatchData?.notes || ''}
                                    onChange={(e) => updateBatchData('notes', e.target.value)}
                                    onBlur={(e) => updateBatchData('notes', e.target.value, true)}
                                ></textarea>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button type="button" onClick={handlePerformRun}>
                    Perform Run
                </button>
            </form>
        </div >
    );
};

export default BrewBriteStatus;

